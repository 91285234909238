<template>
    <div class="permission-page">
        <router-link class="back-previous-screen" to="/bank/settings/bank-settings/permission-groups">
            {{ "< " + $t("Back to [Previous Screen]", { previousScreen: $t("Bank Settings") }) }}
        </router-link>

        <CustomLoader v-if="loading" height="400px" />

        <template v-else>
            <PermissionGroupForm
                class="mb-7"
                ref="permissionGroupForm"
                :title="$t('Create Permission Group')"
                :submitted="submitted"
                :countries-list="bankCountriesList"
                :customers-list="bankCustomersList"
                :product-areas-list="bankProductAreasList"
            />

            <PermissionGroupUsers
                ref="permissionGroupUsers"
                :users-data-list="bankUsersData"
            />

            <div class="permission-page__footer">
                <button class="main-btn-outline btn" @click="handleCancel">{{ $t('Cancel') }}</button>

                <button
                    :data-kt-indicator="submitLoading ? 'on' : 'off'"
                    :disabled="submitLoading"
                    class="btn main-btn"
                    @click="handleSave">
                    <span class="indicator-label"> {{ $t("Save") }} </span>

                    <span class="indicator-progress">
                        {{ $t("pleaseWait") }}
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
            </div>
        </template>
    </div>
</template>

<script>
import store from "@/store";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import PermissionGroupForm from "@/buying-teams/pages/bank/settings/bank/components/permission/PermissionGroupForm.vue";
import PermissionGroupUsers from "@/buying-teams/pages/bank/settings/bank/components/permission/PermissionGroupUsers.vue";
import NotificationService from "@/buying-teams/services/NotificationService";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader.vue";
import { BankUserPermissionTypeEnum } from "@/store/enums/BankUserPermissionTypeEnum";
import { filterAllowedCustomers } from "@/store/models/bank/permissions/BankPermissionGroup";

export default {
    name: "AddPermissionGroup",
    components: {
        CustomLoader,
        PermissionGroupUsers,
        PermissionGroupForm
    },
    data() {
        return {
            submitted: false,
            submitLoading: false,
            loading: true,
            bankUsersData: [],
        }
    },
    computed: {
        bankData() {
            return store.getters.bank;
        },
        currentUser() {
            return store.getters.currentUser;
        },
        bankProductAreasList() {
            return store.getters.getBankProductAreas;
        },
        bankCustomersList() {
            return store.getters.getBankCustomersList;
        },
        bankCustomersListIds() {
            return this.bankCustomersList.map(c => c.id);
        },
        bankCountriesList() {
            return store.getters.getBankCountries;
        },
    },
    mounted() {
        if (this.currentUser && !this.currentUser.can(BankUserPermissionTypeEnum.CHANGE_USER_GROUP_PERMISSIONS)) {
            this.$router.push('/bank/settings/bank-settings/permission-groups');
        }

        setCurrentPageBreadcrumbs({
            title: this.bankData.name,
            logo: this.bankData.icon_path
        });

        this.handleGetUsersList();
    },
    methods: {
        handleCancel() {
            this.$router.push({ name: 'permission-groups' });
        },
        handleGetUsersList() {
            store.dispatch('getUsersList').then(res => {
                this.bankUsersData = res;
            }).finally(() => {
                this.loading = false;
            })
        },
        handleSave() {
            const permissionGroupForm = this.$refs.permissionGroupForm;
            const permissionGroupUsers = this.$refs.permissionGroupUsers;
            this.submitted = true;

            if (permissionGroupForm.isFormValid) {
                this.submitLoading = true;
                const payload = {
                    ...permissionGroupForm.formData,
                    users: permissionGroupUsers.selectedUsersIds
                }
                payload.customers = filterAllowedCustomers(permissionGroupForm.formData.customers, this.bankCustomersListIds);

                store.dispatch('addPermissionsGroup', payload)
                    .then(() => {
                        NotificationService.swalNotify({ confirmButtonText: this.$t("OK") });
                        this.$router.push({ name: 'permission-groups' });
                    })
                    .finally(() => {
                        this.submitLoading = false;
                    })
            } else {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth", });
            }

        }
    }
}
</script>

<style lang="scss">
.permission-page {
    &__footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-top: 34px;
        padding-right: 40px;
        padding-bottom: 5px;
    }
}
</style>
